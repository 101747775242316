import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

/**
 * Tipo Não-conformidade
 */
export interface NonComplianceType {
  id: string
  titulo: string
  descricao: string
  condominioId: string
  capitulo: string
  codigo: string
  artigo: string
  inciso: string
  categoriaNaoConformidadeId?: string
  categoriaNaoConformidade?: string
  rvtci?: number
  rvtMulta?: number
  ativo?: string | boolean
  multaRepete?: string | boolean
}

export type FetchNonComplianceTypesOptions = PaginatedEndpointOptions<
  NonComplianceType
>

export type FetchNonComplianceTypeResponse = PaginatedEndpointResponse<
  NonComplianceType
>

/**
 * Carrega lista de Tipos Não-Conformidade paginados e filtradas pela query.
 */
export async function fetchNonComplianceTypes(
  options?: FetchNonComplianceTypesOptions
) {
  try {
    const { data } = await api.get<FetchNonComplianceTypeResponse>(
      '/TipoNaoConformidade',
      { params: options }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de Tipo Não-Conformidade de um condomínio
 */
export async function fetchNonComplianceTypesFromCondominium(
  condominiumId: string
) {
  try {
    const endpoint = `/Condominio/${condominiumId}/tipoNaoConformidades`

    const { data } = await api.get<NonComplianceType[]>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um Tipo Não-Conformidade pelo id.
 */
export async function fetchNonComplianceType(id: string) {
  try {
    const { data } = await api.get<NonComplianceType>(
      `TipoNaoConformidade/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Reabre uma Não-Conformidade pelo id.
 */
export async function reabrirNaoConformidade(id: string) {
  try {
    const { data } = await api.post(
      `NaoConformidade/reabrirNaoConformidade/${id}`
    )
    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveNonComplianceTypePayload = SaveResourcePayload<
  NonComplianceType
>

/**
 * Salva um Tipo Não-Conformidade, se houver id altera o existente.
 */
export async function saveNonComplianceType(
  nonComplianceType: SaveNonComplianceTypePayload
) {
  try {
    const request = nonComplianceType.id ? api.put : api.post

    const { data } = await request<NonComplianceType>(
      'TipoNaoConformidade',
      nonComplianceType
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga um Tipo Não-Conformidade pelo id.
 */
export async function deleteNonComplianceType(id: string) {
  try {
    const { data } = await api.delete<NonComplianceType>(
      `TipoNaoConformidade/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
